<template>

<div class="viewFail table-hook fadeInRight">
    <el-table ref="table" :data="tableData">
        <el-table-column label="出错时间" prop="createDate" show-overflow-tooltip>
            <template slot-scope="scope">
                <span>{{createDate(scope.row.createDate)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="接入号" prop="phone"></el-table-column>
        <el-table-column label="ICCID" prop="iccidMark"></el-table-column>
        <el-table-column label="失败原因" prop="failReason"></el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</div>

</template>

<script type="text/ecmascript-6">
import {batchrechargefail} from '@/api/cardManagement/batchRechargeLog.js'
export default {
    data(){
      return {
          tableData:[],
          pageNo:1,
          pageSize:15,
          total:0
      }
    },
    mounted(){
        this._batchrechargefail()
    },
    methods:{
        createDate(val){
            let date = new Date(val);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            let d = date.getDate();
            let hour = date.getHours(),min = date.getMinutes();
            return y + '-' +m + '-' + d+' '+hour+':'+min;
        },
        handleSizeChange(val){
            this.pageSize = val
        },
        handleCurrentChange(val){
            this.pageNo = val
        },
        _batchrechargefail(){
            let params = this.$route.query
            params.pageSize = this.pageSize
            params.pageNo = this.pageNo
            batchrechargefail(params).then((res) =>{
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.pageNo = res.pageNo
                this.total = res.total
            })
        }
    }

}
</script>
